import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _363a985e = () => interopDefault(import('../pages/aktionen.vue' /* webpackChunkName: "pages/aktionen" */))
const _10a58313 = () => interopDefault(import('../pages/fortbildungen.vue' /* webpackChunkName: "pages/fortbildungen" */))
const _a2e98706 = () => interopDefault(import('../pages/impressum.vue' /* webpackChunkName: "pages/impressum" */))
const _38483854 = () => interopDefault(import('../pages/live.vue' /* webpackChunkName: "pages/live" */))
const _3b011b86 = () => interopDefault(import('../pages/news.vue' /* webpackChunkName: "pages/news" */))
const _3e9a309a = () => interopDefault(import('../pages/unterricht.vue' /* webpackChunkName: "pages/unterricht" */))
const _57e5a9aa = () => interopDefault(import('../pages/aktion/old_id.vue' /* webpackChunkName: "pages/aktion/old_id" */))
const _455fa11d = () => interopDefault(import('../pages/materialien/old_id..vue' /* webpackChunkName: "pages/materialien/old_id." */))
const _d0cf3d5a = () => interopDefault(import('../pages/aktion/_id.vue' /* webpackChunkName: "pages/aktion/_id" */))
const _44372fd6 = () => interopDefault(import('../pages/fortbildung/_id.vue' /* webpackChunkName: "pages/fortbildung/_id" */))
const _3f8ea7fc = () => interopDefault(import('../pages/materialien/_id.vue' /* webpackChunkName: "pages/materialien/_id" */))
const _0b034a35 = () => interopDefault(import('../pages/post/_id.vue' /* webpackChunkName: "pages/post/_id" */))
const _169300a6 = () => interopDefault(import('../pages/temp/_id/posts.vue' /* webpackChunkName: "pages/temp/_id/posts" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _01c2d8ed = () => interopDefault(import('../pages/_single.vue' /* webpackChunkName: "pages/_single" */))
const _690004e7 = () => interopDefault(import('../pages/_postId/single.vue' /* webpackChunkName: "pages/_postId/single" */))
const _0942e35b = () => interopDefault(import('../pages/_postId/singleTemp.vue' /* webpackChunkName: "pages/_postId/singleTemp" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aktionen",
    component: _363a985e,
    name: "aktionen"
  }, {
    path: "/fortbildungen",
    component: _10a58313,
    name: "fortbildungen"
  }, {
    path: "/impressum",
    component: _a2e98706,
    name: "impressum"
  }, {
    path: "/live",
    component: _38483854,
    name: "live"
  }, {
    path: "/news",
    component: _3b011b86,
    name: "news"
  }, {
    path: "/unterricht",
    component: _3e9a309a,
    name: "unterricht"
  }, {
    path: "/aktion/old_id",
    component: _57e5a9aa,
    name: "aktion-old_id"
  }, {
    path: "/materialien/old_id.",
    component: _455fa11d,
    name: "materialien-old_id."
  }, {
    path: "/aktion/:id?",
    component: _d0cf3d5a,
    name: "aktion-id"
  }, {
    path: "/fortbildung/:id?",
    component: _44372fd6,
    name: "fortbildung-id"
  }, {
    path: "/materialien/:id?",
    component: _3f8ea7fc,
    name: "materialien-id"
  }, {
    path: "/post/:id?",
    component: _0b034a35,
    name: "post-id"
  }, {
    path: "/temp/:id?/posts",
    component: _169300a6,
    name: "temp-id-posts"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/:single",
    component: _01c2d8ed,
    name: "single"
  }, {
    path: "/:postId/single",
    component: _690004e7,
    name: "postId-single"
  }, {
    path: "/:postId/singleTemp",
    component: _0942e35b,
    name: "postId-singleTemp"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
