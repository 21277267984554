export default function(req, res, next) {
    const redirects = [
      {
        from: "/gdc", 
        to: "http://airtable.de/sowieso"
      } 
    ]
    const redirect = redirects.find((r) => r.from === req.url)
    if (redirect) {
      res.writeHead(302, { Location: redirect.to })
      res.end()
    } else {
      next()
    }
  }