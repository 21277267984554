import Vue from 'vue'
import VueGtag from 'vue-gtag'

export default ({ app,store }) => {

const getGDPR = localStorage.getItem('GDPR:accepted');

  Vue.use(VueGtag, {
    config: { id: 'G-0GSH70BFG2' },
    bootstrap:'true',
    appName: 'Esero',
    // enabled: store.state.acceptGA,
    

  }, app.router);
}
