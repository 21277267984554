<template>
  <div>
    <!-- <div v-if="this.$route.path === '/'"> -->
      <Navbar/>
    <!-- </div> -->
    <!-- <div v-else> -->
      <!-- <NavbarSingle/> -->
    <!-- </div> -->
    <nuxt/>
    <Footer/>
  </div>
</template>

<script>
  import Navbar from '~/components/Navbar.vue';
  import NavbarSingle from '~/components/NavbarSingle.vue';
  import Footer from '~/components/Footer.vue';
  // or alternatively use @ to go into root folder!!!!!!!!!!!!!!!!!!!!!!!!!
  // import Navbar from '@/components/Navbar.vue';

  export default {
    components: {
      Navbar,
      NavbarSingle,
      Footer
    }
    // ,
    // data() {
    //   return {
    //     yesyes: this.$route.path
    //   }
    // },
    // computed: {
    //   getTheCurrentNuxtRoute() {
    //     this.yesyes = this.$route.path
    //     // return $nuxt.$route
    //   }
    // }
  }
</script>

<style>
html {
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: border-box;
  margin: 0;
}

.button--green {
  display: inline-block;
  border-radius: 4px;
  border: 1px solid #3b8070;
  color: #3b8070;
  text-decoration: none;
  padding: 10px 30px;
}

.button--green:hover {
  color: #fff;
  background-color: #3b8070;
}

.button--grey {
  display: inline-block;
  border-radius: 4px;
  border: 1px solid #35495e;
  color: #35495e;
  text-decoration: none;
  padding: 10px 30px;
  margin-left: 15px;
}

.button--grey:hover {
  color: #fff;
  background-color: #35495e;
}
</style>
