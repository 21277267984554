<template>
  <div> 

    <!-- <div v-if="this.nuxtRoute === '/'">
    whoooooooooooooooooooooooooooooooooo
    </div> -->
  <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
    <!-- <div v-else> -->

  <!-- <nav class="navbar navbar-expand-lg navbar-dark fixed-top"> -->
    <!-- </div> -->

    <!-- add class opac if transparent from start -->

    <!-- <a class="navbar-brand" href="#">Esero Germany</a> -->

    <!-- <img src="~/assets/esero_flag_sign_Germany.png" class="nodisplay" alt="Responsive image"> -->

    <!-- <img src="~/assets/esero_flag_sign_Germany.png" class="display-below" alt="Responsive image"> -->

    <!-- <img src="~/assets/esero_flag_sign_Germany_blue.png" id="navlogo-toggle" alt="Responsive image"> -->
    <!-- <img :src="[isActive ? '~/assets/esero_flag_sign_Germany_blue.png' : '~/assets/flag_trans']" id="navlogo-toggle" alt="Responsive image"> -->
    <!-- <div v-bind:class="[isActive ? 'navlogosmall' : 'navlogo']"></div> -->
    <!-- <div v-bind:class="window.innerWidth < 1350 ? 'navlogosmall' : 'navlogo'"></div> -->
    <!-- <div v-bind:class="[$(window).width() < 1350 ? 'navlogosmall' : 'navlogo']"></div> -->
    <!-- <div v-bind:class="windowwidth < 1350 ? 'navlogosmall' : 'navlogo'"></div> -->
    <!-- <div class='div' v-bind:class="[isActive ? 'navlogosmall' : 'navlogo']" @click="toggleClass()"></div> -->

    <!-- ***switch navbar logos conditional html style -->
    <div v-if="windowwidth < 1350">
      <img src="~/assets/esero_country_name_blue_r.png" id="navlogo-toggle" class="opac" alt="Responsive image">
    </div>
    <div v-else>
      <img src="~/assets/esero_flag_sign_Germany_blue.png" id="navlogo-toggle" class="opac" alt="Responsive image">
    </div>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ml-auto"> <!-- mr-auto for pushing nav items to the left -->
        
        <!-- another way of writing -->
        <!-- <nuxt-link to="/unterricht" tag="li"><a>test</a></nuxt-link> -->
        
        <!-- =============MAIN QUESTION HERE HOW CAN I MAKE THE LINKS DYNAMIC???
        all the pages will be the same structure, just different dynamic content -->
        
        <li class="nav-item active">
          <!-- <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a> -->
          <nuxt-link exact to="/" class="nav-link">HOME<span class="sr-only">(current)</span></nuxt-link>
        </li>
        <li class="nav-item">
          <nuxt-link to="/news" class="nav-link">NEWS</nuxt-link>
        </li>
        <!-- <li class="nav-item">
          <nuxt-link to="/live" class="nav-link">LIVE</nuxt-link>
        </li> -->
        <li class="nav-item">
          <nuxt-link to="/fortbildungen" class="nav-link">FORTBILDUNGEN</nuxt-link>
        </li>
        <li class="nav-item">
          <!-- <a class="nav-link" href="#">Link</a> -->
          <nuxt-link to="/unterricht" class="nav-link">UNTERRICHT</nuxt-link>
        </li>
        <li class="nav-item">
          <nuxt-link to="/aktionen" class="nav-link">AKTIONEN & WETTBEWERBE</nuxt-link>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#about">ÜBER UNS</a>
          <!-- <nuxt-link to="/kontakt" class="nav-link">Kontakt</nuxt-link> -->
        </li>
        <!-- <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Dropdown
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" href="#">Action</a>
            <a class="dropdown-item" href="#">Another action</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#">Something else here</a>
          </div>
        </li> -->
      </ul>
    </div>

  </nav>

  <!-- <img src="~/assets/esero_flag_sign_Germany.png" class="esero-header" alt="Responsive image"> -->


    <!-- <nav>
      <div class="title">
        <nuxt-link to="/unterricht">Unterricht</nuxt-link>
        <nuxt-link to="/prata">Prata</nuxt-link>
        <nuxt-link exact to="/">Home</nuxt-link>
      </div>
    </nav> -->

  </div>
</template>


<script>
// import $ from 'jquery'
// import 'bootstrap'
// require('bootstrap')

  export default {
    // data() {
    data() {
      return {
        isActive: false,
        windowwidth: 100,
        nuxtRoute: this.$route.path
      }
    },
    // beforeMount: function() {
        // this.windowwidth = $( window ).width();
        // this.windowwidth = document.body.clientWidth;
    // },
    // mounted: () => {
    mounted: function() {
    // mounted () {
      
        // $("button.navbar-toggler").click(function(){
        //   if ( $( this ).hasClass( "collapsed" ) ) {
        //     console.log('show')
        //   }
        // });

        // this is how you would find out if class is there and then do something.. but it's not the right place here
        // if ($( "#navbarSupportedContent" ).hasClass( "show" )) {
          // console.log('show')
          // $( "#specificDiv" ).addClass( "text" );
        // }
        // if ($( "button.navbar-toggler" ).hasClass( "collapsed" )) {
          // console.log('show')
          // alert("The paragraph was clicked.");
          // $( "#specificDiv" ).addClass( "text" );
        // }
        // if ( $( "button.navbar-toggler" ).is( "collapsed" ) ) {
            // alert("The paragraph was clicked.");
        // }

        // ### CHANGE all parts with window.on... 
        // *****! use addEventListener instead of window.onscroll!!! That way you don't override window.onscroll! Multiple window.onscroll doesn't work!!!

        // console.log(process.env.baseUrl);
        // console.log(context.env.baseUrl);
        this.windowwidth = $( window ).width();

        // console.log(this.$route)
        // console.log($nuxt.$route)

        // if (this.nuxtRoute === '/') {
          // transition effect for navbar after scroll
          window.onscroll = function () { scrollFunction() };
          function scrollFunction() {
            if (document.body.scrollTop > 400 || document.documentElement.scrollTop > 400) {
              // doing transition effects like this requires enquing jquery ui!
              // $('nav').removeClass('navbar-light bg-light').addClass('navbar-dark bg-dark shrink', 200, 'swing');
              // > alternatively do in css
              // following line to change the complete style of navbar... not needed for esero anymore
              // $('nav').removeClass('navbar-light bg-light').addClass('navbar-dark bg-dark shrink');
              $('nav').removeClass('navbar-light bg-light').addClass('navbar-dark bg-dark');
              
              $('nav').addClass('navbarshadow normal').removeClass('tiny');
              
              // $('nav').addClass('shrink navbarshadow').removeClass('opac');
              // $('nav img').removeClass('display-below').addClass('display');
              $('nav img').removeClass('display-below').removeClass('opac');
            } else if (document.body.scrollTop < 400 || document.documentElement.scrollTop < 400) {
              // $('nav').removeClass('navbar-dark bg-dark shrink').addClass('navbar-light bg-light');
              $('nav').removeClass('navbar-dark bg-dark').addClass('navbar-light bg-light');
              
              $('nav').removeClass('navbarshadow normal').addClass('tiny');
              
              // $('nav').removeClass('shrink navbarshadow').addClass('opac');
              // $('nav.navbar').attr('opacity', '0');
              // $('nav img').removeClass('display').addClass('display-below');
              $('nav img').removeClass('display').addClass('opac');
            }
          }
        // } 
        // else {
        //   $('nav').removeClass('navbar-light bg-light').addClass('navbar-dark bg-dark');
              
        //       $('nav').addClass('navbarshadow normal').removeClass('tiny');
        // }

        // >>> icon switcher
        // window.onresize = function () { resizeFunction() };
        // function resizeFunction() {
        //   if ($(window).width() < 1350) {
        //       $("#navlogo-toggle").attr("src", "~/assets/flag_trans.png")
        //   } else if ($(window).width() > 1350) {
        //       $("#navlogo-toggle").attr("src", "~/assets/esero_flag_sign_Germany_blue.png")
        //   }
        // }
        // $( window ).resize(function() {
        //     if($( window ).width() < 1350) { 
        //         $("img#navlogo-toggle").attr("src","../assets/flag_trans.png");
        //     } else {
        //         $("img#navlogo-toggle").attr("src","../assets/esero_flag_sign_Germany_blue.png");
        //     }
        // });

        // ????????
        // WHY CANT I USE REGULAR this????? it is not an arrow function
        let vm = this;
        // vm.resizeFunction();
        // window.onresize = function () { vm.resizeFunction() };
        window.onresize = function () { vm.resizer() };


        // function resizeFunction() {
          // if($( window ).width() < 1350) {
            // this.data.isActive = true;
            // this.isActive = true;
            // return this.isActive = true;
            // vm.isActive = true;
            // console.log('small')
            // $("img#navlogo-toggle").attr("src", "/assets/flag_trans.png")
          // } else {
            // this.isActive = false;
            // console.log('wide')
            // $("img#navlogo-toggle").attr("src","/assets/esero_flag_sign_Germany_blue.png");
          // }
        // }

        // window.onresize = function () { resizeFunction() };
        // function resizeFunction() {
        //   window.addEventListener('resize', this.handleResize)
        // }

        // ***smooth scrolling for single page links
        $(document).on('click', 'a[href^="#"]', function (event) {
          event.preventDefault();
          // never ever use slim version of jquery, was hanging at animate not a function forever
          $('html, body').animate({
            scrollTop: $($.attr(this, 'href')).offset().top
          }, 500);
        });
    },
    methods: {
      resizeFunction() {
        if($( window ).width() < 1350) {
          this.isActive = true;
          // $("navlogo-toggle").attr("src","/assets/flag_trans.png");
          // console.log('klein')
        } else {
          this.isActive = false;
        }
      },
      resizer() {
          this.windowwidth = $( window ).width();
      }
    }
    // ,
    // computed: {
    //   getTheCurrentNuxtRoute() {
    //     this.nuxtRoute = $nuxt.$route.path
    //     // return $nuxt.$route
    //   }
    // }

  }
</script>

<style scoped>
/* override default bootstrap background to make transparent */
.navbar.bg-light {
    /* background-color: rgba(255, 255, 255, 0) !important; */
    /* this should be the color #0098DB */
    /* background-color: #0098DB !important; */
    /* but color came out a bit different in the header image: */
    /* background-color: #009ada !important; */
    background-color: #f9f9f9 !important;
}

/* nav {
  transition: all 0.4s ease;
} */
nav.navbar {
  /* careful with height settings, messes up expanded view.. need to find correct accessor for collapsed view */
  /* min-height: 80px; */
  /* max-height: 40px; */
  /* transition: all 0.4s ease; */ /* not needed when done through gsap animation in index.vue */
  background-color: #009ada !important;
  /* background-color: #f9f9f9 !important; */
  /* box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.65); */

  /* the following was from test */
  /* visibility: hidden; */
  /* top: -200px; */
  
  /* height: 75px; */
}
/* nav.navbar.opac {
  opacity: 0 !important;
} */
.navbarshadow {
  box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.65);
}
nav.navbar.shrink {
    min-height: 56px;
  }
/* nav.navbar.normal { */
    /* max-height: 80px; */
  /* } */

/* nav.navbar.tiny {
    max-height: 40px;
  } */

/* .esero-header {
  max-height: 50px;
} */
/* .eserologofull {
  background-image: url(~/assets/esero_flag_sign_Germany_blue.png);
}
.eserologosmall {
} */
nav.navbar img {
/* nav.navbar .eserologofull { */
  /* min-height: 80px; */
  /* height: 60px; */
  height: 80px;
  /* max-height: 100px; */
  transition: all 0.4s ease;
  margin-left: -16px;
}
nav.navbar img.opac {
  opacity: 0 !important;
  height: 40px;
}
/* nav.navbar img {
  position: fixed;
  max-height: 100px;
  transition: all 0.4s ease;
  margin-left: -16px;
} */
/* @media only screen and (max-width: 1550px) {
    nav.navbar img {
        max-height: 70px;
    }
} */
nav.navbar.shrink img {
/* nav.navbar.shrink .eserologofull { */
  max-height: 70px;
  /* -webkit-transition: max-height 2s; */
  /* transition: max-height 2s; */
  /* transition: 2s; */
}
/* *** navbar logo switcher toggle */
.navlogo {
  background-image: url(~/assets/esero_flag_sign_Germany_blue.png);
  max-height: 100px;
  transition: all 0.4s ease;
  margin-left: -16px;
}
.navlogosmall {
  background-image: url(~/assets/esero_country_name_blue_r.png);
  max-height: 70px;
  transition: all 0.4s ease;
  margin-left: -16px;
}


/* .esero-header {
  padding-top: 80px;
} */
/* .nodisplay {
  display: none;
} */
/* .display-below {
  position: fixed;
  top: 80px;
} */
/* .display-inline {
  display: block;
} */

  /* nav {
    background: #222;
    padding: 5px 10px 10px;
    width: 100vw;
    color: white;
    position: fixed;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: center;
        align-content: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  nav ul {
    padding-left: 0;
  }
  nav a {
    color: white;
    text-decoration: none;
    padding: 0 10px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  nav a:hover {
    color: #E6534B;
  } */
  .stamp {
    width: 30px;
    height: 30px;
    margin: 10px;
    float: left;
  }
  .cart {
    width: 40px;
    height: 40px;
    margin: 5px;
  }
</style>