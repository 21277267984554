<template>
  <div> 


  <!-- <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top"> -->
  

  <nav class="navbar navbar-expand-lg navbar-dark fixed-top">

    <!-- ***switch navbar logos conditional html style -->
    <div v-if="windowwidth < 1350">
      <img src="~/assets/esero_country_name_blue_r.png" id="navlogo-toggle" class="opac" alt="Responsive image">
    </div>
    <div v-else>
      <img src="~/assets/esero_flag_sign_Germany_blue.png" id="navlogo-toggle" class="opac" alt="Responsive image">
    </div>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ml-auto"> <!-- mr-auto for pushing nav items to the left -->
        
        <!-- another way of writing -->
        <!-- <nuxt-link to="/unterricht" tag="li"><a>test</a></nuxt-link> -->
        
        <!-- =============MAIN QUESTION HERE HOW CAN I MAKE THE LINKS DYNAMIC???
        all the pages will be the same structure, just different dynamic content -->
        
        <li class="nav-item">
          <!-- <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a> -->
          <nuxt-link exact to="/" class="nav-link">HOME<span class="sr-only">(current)</span></nuxt-link>
        </li>
        <li class="nav-item">
          <nuxt-link to="/news" class="nav-link">NEWS</nuxt-link>
        </li>
        <li class="nav-item">
          <nuxt-link to="/live" class="nav-link">LIVE</nuxt-link>
        </li>
        <li class="nav-item">
          <nuxt-link to="/fortbildungen" class="nav-link">FORTBILDUNGEN</nuxt-link>
        </li>
        <li class="nav-item">
          <!-- <a class="nav-link" href="#">Link</a> -->
          <nuxt-link to="/unterricht" class="nav-link">UNTERRICHT</nuxt-link>
        </li>
        <li class="nav-item">
          <nuxt-link to="/aktionen" class="nav-link">AKTIONEN & WETTBEWERBE</nuxt-link>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#about">ÜBER UNS & KONTAKT</a>
          <!-- <nuxt-link to="/kontakt" class="nav-link">Kontakt</nuxt-link> -->
        </li>
        <!-- <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Dropdown
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" href="#">Action</a>
            <a class="dropdown-item" href="#">Another action</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#">Something else here</a>
          </div>
        </li> -->
      </ul>
    </div>

  </nav>

  </div>
</template>


<script>
// import $ from 'jquery'
// import 'bootstrap'
// require('bootstrap')

  export default {
    // data() {
    data() {
      return {
        isActive: false,
        windowwidth: 100,
        nuxtRoute: this.$route.path
      }
    },
    // beforeMount: function() {
        // this.windowwidth = $( window ).width();
        // this.windowwidth = document.body.clientWidth;
    // },
    // mounted: () => {
    mounted: function() {
   

        // $(".nav .nav-item").on("click", function(){
        //     $(".nav").find(".active").removeClass("active");
        //     $(this).addClass("active");
        // });
        // $(".nav-link").on("click", function(){
        //     console.log('fuck')
        //     $("ul").find(".active").removeClass("active");
        //     $(this).addClass("navItemActiveBackground");
        // });

       
        this.windowwidth = $( window ).width();

       


        // ????????
        // WHY CANT I USE REGULAR this????? it is not an arrow function
        let vm = this;
        // vm.resizeFunction();
        // window.onresize = function () { vm.resizeFunction() };
        window.onresize = function () { vm.resizer() };


        // ***smooth scrolling for single page links
        $(document).on('click', 'a[href^="#"]', function (event) {
          event.preventDefault();
          // never ever use slim version of jquery, was hanging at animate not a function forever
          $('html, body').animate({
            scrollTop: $($.attr(this, 'href')).offset().top
          }, 500);
        });
    },
    methods: {
      resizeFunction() {
        if($( window ).width() < 1350) {
          this.isActive = true;
          // $("navlogo-toggle").attr("src","/assets/flag_trans.png");
          // console.log('klein')
        } else {
          this.isActive = false;
        }
      },
      resizer() {
          this.windowwidth = $( window ).width();
      }
    }
    // ,
    // computed: {
    //   getTheCurrentNuxtRoute() {
    //     this.nuxtRoute = $nuxt.$route.path
    //     // return $nuxt.$route
    //   }
    // }

  }
</script>

<style scoped>

nav.navbar {
  /* careful with height settings, messes up expanded view.. need to find correct accessor for collapsed view */
  /* min-height: 80px; */
  /* max-height: 40px; */
  /* transition: all 0.4s ease; */ /* not needed when done through gsap animation in index.vue */
  background-color: #009ada !important;
  /* background-color: #f9f9f9 !important; */
  /* color: rgba(255,255,255,.5) !important; */
  /* box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.65); */

  /* the following was from test */
  /* visibility: hidden; */
  /* top: -200px; */
  
  /* height: 75px; */
}
/* nav.navbar.opac {
  opacity: 0 !important;
} */
.navbarshadow {
  box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.65);
}
nav.navbar.shrink {
    min-height: 56px;
  }
/* nav.navbar.normal { */
    /* max-height: 80px; */
  /* } */

/* nav.navbar.tiny {
    max-height: 40px;
  } */

/* .esero-header {
  max-height: 50px;
} */
/* .eserologofull {
  background-image: url(~/assets/esero_flag_sign_Germany_blue.png);
}
.eserologosmall {
} */
nav.navbar img {
/* nav.navbar .eserologofull { */
  /* min-height: 80px; */
  height: 60px;
  /* max-height: 100px; */
  transition: all 0.4s ease;
  margin-left: -16px;
}
nav.navbar img.opac {
  opacity: 0 !important;
  height: 40px;
}
/* nav.navbar img {
  position: fixed;
  max-height: 100px;
  transition: all 0.4s ease;
  margin-left: -16px;
} */
/* @media only screen and (max-width: 1550px) {
    nav.navbar img {
        max-height: 70px;
    }
} */
nav.navbar.shrink img {
/* nav.navbar.shrink .eserologofull { */
  max-height: 70px;
  /* -webkit-transition: max-height 2s; */
  /* transition: max-height 2s; */
  /* transition: 2s; */
}
/* *** navbar logo switcher toggle */
.navlogo {
  background-image: url(~/assets/esero_flag_sign_Germany_blue.png);
  max-height: 100px;
  transition: all 0.4s ease;
  margin-left: -16px;
}
.navlogosmall {
  background-image: url(~/assets/esero_country_name_blue_r.png);
  max-height: 70px;
  transition: all 0.4s ease;
  margin-left: -16px;
}

.navItemActiveBackground {
    background-color: red;
}


/* .esero-header {
  padding-top: 80px;
} */
/* .nodisplay {
  display: none;
} */
/* .display-below {
  position: fixed;
  top: 80px;
} */
/* .display-inline {
  display: block;
} */

  /* nav {
    background: #222;
    padding: 5px 10px 10px;
    width: 100vw;
    color: white;
    position: fixed;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: center;
        align-content: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  nav ul {
    padding-left: 0;
  }
  nav a {
    color: white;
    text-decoration: none;
    padding: 0 10px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  nav a:hover {
    color: #E6534B;
  } */
  .stamp {
    width: 30px;
    height: 30px;
    margin: 10px;
    float: left;
  }
  .cart {
    width: 40px;
    height: 40px;
    margin: 5px;
  }
</style>