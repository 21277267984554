<template>
    <div class="footer-section">

        <!-- <div id="erfahreMehr" class="d-lg-flex align-items-center flex-column whitegrey2">
          <h1 class="animateUp">Erfahre mehr</h1>
            <div class="button--green animateUp">Jetzt Kontakt aufnehmen</div>
        </div> -->

        <!-- partner logos slider -->
        <div class="container-fluid footer-2" id="about">
            <div class="container">
                <!-- <h3>
                    About
                    <small class="text-muted">Esero Germany</small>
                </h3> -->
                <div class="d-flex flex-row flex-wrap justify-content-center align-items-center">
                    <img class="topLogos" src="~/assets/logos/drl_white_trans_h400.png">
                    <img class="topLogos" style="height: 50px" src="~/assets/logos/rub_long_white_trans_h400.png">
                    <img class="topLogos" src="~/assets/logos/ESA_logo_2020_White.png">
                </div>
                <div class="heading2">Über ESERO Germany</div>
                <p class="content2">
                    Das <b>European Space Education Resource Office (ESERO)</b> in Deutschland ist ein gemeinsames Projekt der ESA und des Deutschen Zentrums für Luft- und Raumfahrt (DLR). 
                    Den Zuschlag zur Gründung des ESERO-Büros hat die Ruhr-Universität Bochum mit einem engagierten Konsortium aus zehn Institutionen aus Nordrhein-Westfalen erhalten. 
                    Das ESERO Office hat das Ziel, Schüler*Innen für MINT-Themen zu begeistern. Themen der Raumfahrt werden hierzu spannend und innovativ in den Schulunterricht integriert und die Kompetenzen von Schüler*innen in den Fächern Mathematik, Informatik, Naturwissenschaften und Technik (MINT-Fächer) gefördert.
                    <!-- Das ESERO Office hat das Ziel, Themen der Raumfahrt spannend und innovativ in den Schulunterricht zu bringen und die Kompetenzen von Schüler*innen in den Fächern Mathematik, Informatik, Naturwissenschaften und Technik (MINT-Fächer) zu fördern. -->
                </p>
                <div class="customer-logos">
                    <!-- <div class="slide"><a href="https://www.w3schools.com" target="_blank"><img src="~/assets/logo1.png"></a></div> -->
                    <div class="slide"><a href="https://www.ruhr-uni-bochum.de/de/willkommen-der-ruhr-universitaet-bochum" target="_blank"><img src="~/assets/logos/1_RUB.png"></a></div>
                    <div class="slide"><a href="https://www.geographie.ruhr-uni-bochum.de" target="_blank"><img src="~/assets/logos/RUB_GI_RGB_farbe.png"></a></div>
                    <div class="slide"><a href="https://www.geographie.ruhr-uni-bochum.de" target="_blank"><img src="~/assets/logos/indygis.png"></a></div>
                    <div class="slide"><a href="https://www.planetarium-bochum.de/de_DE/home" target="_blank"><img src="~/assets/logos/2_Planetarium.png"></a></div>
                    <div class="slide"><a href="https://www.astro.ruhr-uni-bochum.de/" target="_blank"><img src="~/assets/logos/3_AIRUB.png"></a></div>
                    <div class="slide"><a href="https://www.amsat-dl.org/" target="_blank"><img src="~/assets/logos/4_Amsat.png" style="background: white"></a></div>
                    <div class="slide"><a href="https://www.sternwarte-bochum.de" target="_blank"><img src="~/assets/logos/4_IUZ.png"></a></div>
                    <!-- <div class="slide"><a href="https://www.w3schools.com" target="_blank"><img src="~/assets/logos/5_UoB_Geographie.jpg"></a></div>
                    <div class="slide"><a href="https://www.w3schools.com" target="_blank"><img src="~/assets/logos/6_HCM.png"></a></div>
                    <div class="slide"><a href="https://www.w3schools.com" target="_blank"><img src="~/assets/logos/7_PI.png"></a></div>
                    <div class="slide"><a href="https://www.w3schools.com" target="_blank"><img src="~/assets/logos/8_aifa.jpg"></a></div>
                    <div class="slide"><a href="https://www.w3schools.com" target="_blank"><img src="~/assets/logos/9_zdi.png"></a></div>
                    <div class="slide"><a href="https://www.w3schools.com" target="_blank"><img src="~/assets/logos/10_UoC.png"></a></div>
                    <div class="slide"><a href="https://www.w3schools.com" target="_blank"><img src="~/assets/logos/10_UoC_zdi.png"></a></div> -->
                    
                    <!-- <div class="slide"><a href="https://www.w3schools.com" target="_blank"><img src="/esero/dist/1_RUB.png"></a></div>
                    <div class="slide"><a href="https://www.w3schools.com" target="_blank"><img src="/esero/dist/RUB_GI_RGB_farbe.png"></a></div>
                    <div class="slide"><a href="https://www.w3schools.com" target="_blank"><img src="/esero/dist/logos/indygis.png"></a></div>
                    <div class="slide"><a href="https://www.w3schools.com" target="_blank"><img src="/esero/dist/logos/2_Planetarium.png"></a></div>
                    <div class="slide"><a href="https://www.w3schools.com" target="_blank"><img src="/esero/dist/logos/3_AIRUB.png"></a></div>
                    <div class="slide"><a href="https://www.w3schools.com" target="_blank"><img src="/esero/dist/logos/4_Amsat.png"></a></div>
                    <div class="slide"><a href="https://www.w3schools.com" target="_blank"><img src="/esero/dist/logos/4_IUZ.png"></a></div>
                    <div class="slide"><a href="https://www.w3schools.com" target="_blank"><img src="/esero/dist/logos/5_UoB_Geographie.png"></a></div>
                    <div class="slide"><a href="https://www.w3schools.com" target="_blank"><img src="/esero/dist/logos/6_HCM.png"></a></div>
                    <div class="slide"><a href="https://www.w3schools.com" target="_blank"><img src="/esero/dist/logos/7_PI.png"></a></div>
                    <div class="slide"><a href="https://www.w3schools.com" target="_blank"><img src="/esero/dist/logos/8_aifa.png"></a></div>
                    <div class="slide"><a href="https://www.w3schools.com" target="_blank"><img src="/esero/dist/logos/9_zdi.png"></a></div>
                    <div class="slide"><a href="https://www.w3schools.com" target="_blank"><img src="/esero/dist/logos/10_UoC.png"></a></div>
                    <div class="slide"><a href="https://www.w3schools.com" target="_blank"><img src="/esero/dist/logos/10_UoC_zdi.png"></a></div> -->
                </div>
                <div class="customer-logos">
                    <!-- <div class="slide"><a href="https://www.w3schools.com" target="_blank"><img src="~/assets/logo1.png"></a></div> -->
                    <!-- <div class="slide"><a href="https://www.w3schools.com" target="_blank"><img src="~/assets/logos/1_RUB.png"></a></div>
                    <div class="slide"><a href="https://www.w3schools.com" target="_blank"><img src="~/assets/logos/RUB_GI_RGB_farbe.png"></a></div>
                    <div class="slide"><a href="https://www.w3schools.com" target="_blank"><img src="~/assets/logos/indygis.png"></a></div>
                    <div class="slide"><a href="https://www.w3schools.com" target="_blank"><img src="~/assets/logos/2_Planetarium.png"></a></div>
                    <div class="slide"><a href="https://www.w3schools.com" target="_blank"><img src="~/assets/logos/3_AIRUB.png"></a></div>
                    <div class="slide"><a href="https://www.w3schools.com" target="_blank"><img src="~/assets/logos/4_Amsat.png"></a></div>
                    <div class="slide"><a href="https://www.w3schools.com" target="_blank"><img src="~/assets/logos/4_IUZ.png"></a></div> -->
                    <div class="slide"><a href="https://www.geographie.uni-bonn.de/collage-startseite-1?set_language=de" target="_blank"><img src="~/assets/logos/5_UoB_Geographie.jpg"></a></div>
                    <div class="slide"><a href="https://www.hcm.uni-bonn.de/" target="_blank"><img src="~/assets/logos/6_HCM.png"></a></div>
                    <div class="slide"><a href="https://www.pi.uni-bonn.de/copy_of_start-collage?set_language=de" target="_blank"><img src="~/assets/logos/7_PI.png"></a></div>
                    <div class="slide"><a href="https://astro.uni-bonn.de/" target="_blank"><img src="~/assets/logos/8_aifa.jpg"></a></div>
                    <div class="slide"><a href="https://www.zdi-portal.de/" target="_blank"><img src="~/assets/logos/9_zdi.png"></a></div>
                    <div class="slide"><a href="https://www.uni-koeln.de/" target="_blank"><img src="~/assets/logos/10_UoC.png"></a></div>
                    <div class="slide"><a href="http://zdi-schuelerlabor.uni-koeln.de/15263.html" target="_blank"><img src="~/assets/logos/10_UoC_zdi.png"></a></div>
                </div>
            </div>
        </div>
        <div class="container-fluid footer-3" id="contact">
            <div class="container contact">    
                <div class="row">
                    <div class="col-lg-8">
                        <img width="200px" src="~/assets/flag_trans.png" class="" alt="Responsive image">
                        <!-- <img width="200px" src="/esero/dist/flag_trans.png" class="" alt="Responsive image"> -->
                        <p>
                            ESERO Germany<br>
                            Ruhr-Universität Bochum<br>
                            Geographisches Institut, AG Geomatik<br>
                            Universitätstraße 150<br>
                            44801 Bochum<br>
                            <a class="accentcolor" href="mailto:info@esero.de">Email:</a>
                            info@esero.de
                        </p>
                    </div>
                    <div class="col-lg-4">
                        <p>Nehmen Sie Kontakt mit uns auf. Wir freuen uns über neue Aufgaben.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid footer-4">
            <div class="container impressum d-flex justify-content-center">    
                <nuxt-link to="/impressum/" class="col-lg-2">
                    <!-- <small>All Rights Reserved, 2018. <a class="text-muted">Impress/Copyright</a></small> -->
                    <small class="text-muted">Impressum</small>
                </nuxt-link>
            </div>
        </div>
    </div>
</template>


<script>
import { TweenMax, Back } from 'gsap'
// import 'jquery'
// import 'slick-carousel'
// let Slick=null
// if (process.browser) {
//     Slick = require('slick-carousel').default
// }

export default {
    components: {

    },
    mounted: () => {
        $('.customer-logos').slick({
            autoplay: true,
            // speed: 300,
            arrows: false,
            infinite: true,
            slidesToShow: 1,
            // slidesToScroll: 1,
            centerMode: true,
            variableWidth:true
        });
    },
    transition: {
        mode: 'out-in',
        css: false,
        beforeEnter (el) {
            console.log('beforeEnter unterricht')
            TweenMax.set(el, {
                opacity: 0
            })
        },
        enter (el, done) {
            console.log('enter unterricht')
            TweenMax.to(el, 1, {
                opacity: 1,
                onComplete: done
            })
        },
        leave (el, done) {
            console.log('leave unterricht')
            TweenMax.to(el, 1, {
                opacity: 0,
                x:1000,
                color: 'red',
                onComplete: done
            })
        }
    }
}
</script>

<style scoped>
/* .footer-section {
    
} */
.footer-2 {
    padding-top: 60px;
    padding-bottom: 60px;
    color: #fff;
    /* background-color: #0098db; */
    /* background-color: #00b8f5; */
    background-color: #39424e;
    /* color: #212529; */
    /* background-color: #4ed3ff; */
    /* font-family: ; */
}
.heading2 {
    /* font-family: "Open Sans", sans-serif; */
    font-family: "Open Sans", sans-serif;
    /* font-family: "Open Sans Condensed", sans-serif; */
    text-transform: none;
    font-size: 25px;
    line-height: 35px;
    font-weight: 300;
    letter-spacing: -0.5px;
}
.content2 {
    /* font-family: "Roboto", sans-serif; */
    font-family: "Open Sans", sans-serif;
    /* text-transform: none; */
    /* font-size: 25px; */
    /* line-height: 35px; */
    /* font-weight: 300; */
    /* letter-spacing: -0.5px; */
}

.topLogos {
    height: 70px;
    margin-top: 30px;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 50px;
}


.slick-slide {
    margin-top: 50px;
    margin-bottom: 20px;
    margin-right: 30px;
}
.slick-slide img {
    height: 100px;
}

.contact {
    padding-top: 70px;
    padding-bottom: 100px;
}


.footer-3 {
    color: #ccc;
    background-color: #252525;
}
.footer-4 {
    /* color: #ccc; */
    /* color: #777; */
    background-color: #1c1c1c;
}

.impressum {
    padding-top: 20px;
    padding-bottom: 20px;
}
</style>

